import React,{ useState } from 'react'
import PropTypes from 'prop-types';
import SEO from 'components/seo';
import Layout from 'components/Layout';
import Hero from 'components/Hero';
import ContactMe from 'components/ContactMe' ;
import Article from 'components/Article';
import Img from 'gatsby-image'
import {graphql} from 'gatsby'
import FsLightbox from 'fslightbox-react';


const Single = ({data}) => {

    const [toggler, setToggler] = useState(false);
    const {image,preview,product,title,version} = data.markdownRemark.frontmatter
    const themePic = image.childImageSharp.fluid
    const html = data.markdownRemark.html
    let img_gallery = data.markdownRemark.frontmatter.gallery 
  
    if(img_gallery){
      img_gallery = img_gallery.map( img => {
        return (
                  img.childImageSharp.fluid.src
        )
      })
    }
      return(
        <Layout pageName={title}>
        <SEO title={title} />
            <Hero title={title}></Hero>
            <Article>
            <div className="themeSingle">

                <div className="entry-pic" onClick={ () => setToggler(!toggler) }>
                    <Img fluid={themePic} alt={title}/>
                </div>
                <div className="theme-content">
                    <h2 className="entry-title">                    
                        {title}                        
                    </h2>
                    <h4> osTicket Version - {version}</h4>
                    <div className="entry-text" dangerouslySetInnerHTML={{__html:html }}></div>
       
                  <FsLightbox   
                  toggler={ toggler }
                      sources={ img_gallery }
                  />

                    <div className="entry-footer">
                        <button> 
                            <a href={preview} target="_blank" rel="noopener noreferrer"> Preview </a>
                        </button>
                        <button> 
                            <a href={product} target="_blank" rel="noopener noreferrer"> Purchase-$40</a>
                        </button>
                    </div>
                </div>

            </div>
            </Article>
      <ContactMe></ContactMe>
    </Layout>
    )
    
}

Single.propTypes = {
    data: PropTypes.object.isRequired,
  };
  

export default Single


export const singleQuery = graphql`
  query($id:String!) {
    markdownRemark(id: {eq: $id}) {
    frontmatter {
      preview
      product
      title
      version
      image {
        childImageSharp {
              fluid(maxWidth: 740) {
              ...GatsbyImageSharpFluid_tracedSVG
              }
            }
      }
      gallery {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_tracedSVG
              }
        }
      }
    }
    html
  }

  }
`